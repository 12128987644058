<template>
  <div :class="dropOffPointClasses">
    <div
      v-if="$slots.title"
      class="drop-off-point__title"
    >
      <slot name="title" />
    </div>
    <slot>
      <AddressFormatted
        v-bind="$props"
        class="drop-off-point__address"
      >
        <template
          v-if="$slots.icon"
          #icon
        >
          <slot name="icon" />
        </template>
      </AddressFormatted>
    </slot>
    <div class="drop-off-point__misc">
      <div
        v-if="carrier && carrier.logo"
        class="drop-off-point__carrier"
      >
        <img
          :alt="carrier.name"
          :src="carrier.logo.icon"
        >
      </div>
      <div class="drop-off-point__distance">
        <div>
          <span class="sr-only">{{ $t('Distance to drop-off point') }}</span>
          <span class="ui-badge ui-badge--primary">{{ formattedDistance }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressFormatted from './AddressFormatted'
import { formatDistance } from '@/utils'

export default {
  name: 'DropOffPointAddress',
  components: {
    AddressFormatted,
  },
  props: {
    company_name: { type: String, required: false, default: '' },
    address_1: { type: String, required: true },
    house_number: { type: String, required: true },
    postal_code: { type: String, required: true },
    city: { type: String, required: true },
    country_name: { type: String, required: false, default: '' },
    distance: { type: Number, required: true },
    carrier: { type: Object, required: false, default: () => undefined },
  },
  computed: {
    dropOffPointClasses() {
      return {
        'drop-off-point': true,
        'drop-off-point--with-title': this.$slots.title,
      }
    },
    formattedDistance() {
      return formatDistance(this.distance)
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-off-point {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2rem;

  &.drop-off-point--with-title {
    grid-template-rows: 2rem 1fr 2rem;
  }

  @media (min-width: 37.5rem) {
    grid-template-columns: 1fr 3rem;
    grid-template-rows: 1fr;

    &.drop-off-point--with-title {
      grid-template-rows: 3rem 1fr;
    }
  }
}

.drop-off-point__title {
  font-weight: 700;
  font-size: 1.2rem;
}

.drop-off-point__address {
  font-style: normal;
  word-break: break-all;

  @media (min-width: 37.5rem) {
    grid-column-start: 1;
  }
}

.drop-off-point__misc {
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-column-start: 1;
  grid-row-start: 2;
  margin-top: 0.5rem;

  .drop-off-point--with-title & {
    grid-row-start: 3;
  }

  @media (min-width: 37.5rem) {
    flex-direction: column;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    margin-top: 0;

    .drop-off-point--with-title & {
      grid-row-start: 1;
      grid-column-start: 2;
    }
  }
}

.drop-off-point__distance {
  min-width: 2.8rem;
}

.drop-off-point__carrier {
  width: 3rem;
  text-align: center;
  margin-right: 0.5rem;

  @media (min-width: 37.5rem) {
    margin-top: 0.5rem;
    margin-right: 0;
    order: 2;
  }
}

.drop-off-point__carrier img {
  max-height: 1.5rem;
  max-width: 3rem;
}
</style>
