<template>
  <TheWrapper>
    <h2 class="step__main-title">
      {{ $t('Change drop-off point') }}
    </h2>

    <form
      novalidate="novalidate"
      class="wrapper__container dropoff-search"
      data-test="search-form"
      @submit.prevent="search"
    >
      <label
        for="dropoff-search__input"
        class="dropoff-search__label"
      >
        {{ $t('Search for address, postal code, or city') }}
      </label>
      <div class="dropoff-search__controls">
        <input
          id="dropoff-search__input"
          v-model="addressQuery"
          type="text"
          class="dropoff-search__input"
          data-test="search-input"
        >
        <button
          type="submit"
          class="dropoff-search__submit"
          :disabled="v$.addressQuery.$invalid"
        >
          <FontAwesomeIcon :icon="$options.faSearch" />
          <span class="sr-only">{{ $t('Search') }}</span>
        </button>
      </div>
    </form>

    <form
      novalidate="novalidate"
      class="wrapper__container"
      data-test="selector-form"
      @submit.prevent="next"
    >
      <ItemSelector
        v-for="point in allPoints"
        :key="point.id"
        :checked="point.isSelected"
        :class="!point.open_upcoming_week ? 'item-selector__radio--disable' : ''"
        :disabled="!point.open_upcoming_week"
        :field-id="`drop-off-point-${point.id}`"
        :multiple="false"
        :value="point.id"
        group="dropOffPoints"
        @on-change="dropOffPointChanged"
      >
        <DropOffPointAddress v-bind="point" />

        <div
          v-if="
            point.extra_data &&
              point.extra_data.shop_type === 'locker' &&
              point.carrier &&
              point.carrier.code === 'correos'
          "
          class="ui-margin-0--bottom"
        >
          <span>
            <i18n-t keypath="This location is a locker. Visit the {correosWebsite} for more information.">
              <template #correosWebsite>
                <a
                  href="https://www.correos.es/"
                  target="_blank"
                  rel="noopener noreferrer external"
                >
                  {{ $t('Correos website') }}
                </a>
              </template>
            </i18n-t>
          </span>
        </div>

        <p
          v-if="!point.open_upcoming_week && !isLoading"
          class="ui-margin:bottom-none"
        >
          <FontAwesomeIcon
            :icon="$options.faExclamationTriangle"
            class="item-selector__info-icon"
          />
          <span>{{ $t('Temporarily unavailable') }}</span>
        </p>
      </ItemSelector>
      <p
        v-if="hasErrorFetchingFirstPage"
        class="field-error"
        data-test="error-fetching-first-page"
      >
        {{ $t('We could not load drop-off points. Please, try again.') }}
      </p>
      <p
        v-if="hasErrorFetchingNextPage"
        class="field-error"
        data-test="error-fetching-next-page"
      >
        {{ $t('We could not load other drop-off points. Please, try again.') }}
      </p>
      <UiButton
        v-if="refetchPossible"
        mode="secondary"
        class="main-button"
      >
        <button
          :disabled="isLoading"
          class="main-button__btn secondary-button__btn"
          data-test="load-more"
          type="button"
          @click="loadMore(false)"
        >
          <FontAwesomeIcon
            v-if="isLoading"
            :icon="$options.faSpinner"
            spin
          />
          <template v-else-if="hasErrorFetchingFirstPage || hasErrorFetchingNextPage">
            <span>{{ $t('Try again') }}</span>
          </template>
          <template v-else>
            <span>{{ $t('Load more') }}</span>
          </template>
        </button>
      </UiButton>
      <UiButton
        mode="primary"
        class="main-button"
      >
        <button
          :disabled="disableNext"
          :style="brandStyle"
          class="main-button__btn"
          data-test="choose-point"
          type="submit"
        >
          <span>{{ $t('Apply') }}</span>
          <FontAwesomeIcon :icon="$options.faChevronRight" />
        </button>
      </UiButton>
    </form>
  </TheWrapper>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapState, mapGetters } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
  faSpinner,
  faSearch,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { brandColourMixin } from '@/components/mixins'
import {
  APP_SET_SELECTED_DROP_OFF_POINT,
  APP_SET_LOCATION_TYPE,
  APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT,
} from '@/store/mutation.types'
import { ErrorTypes } from '@/constants.js'
import ItemSelector from '@/components/common/ItemSelector.vue'
import SKELETON_PLACEHOLDER from './DropOffPointViewSkeleton.json'
import TheWrapper from '@/components/layout/TheWrapper'
import { request, endpoint } from '@/api/client.js'
import DropOffPointAddress from '@/components/common/DropOffPointAddress.vue'

export default {
  name: 'DropOffPointView',
  faChevronRight,
  faSpinner,
  faSearch,
  faExclamationTriangle,
  components: {
    DropOffPointAddress,
    FontAwesomeIcon,
    ItemSelector,
    TheWrapper,
    UiButton,
  },
  mixins: [brandColourMixin],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      dropOffPointFetchUrl: undefined,
      dropOffPoints: [],
      fetchDropOffPointsURL: undefined,
      fetchingError: undefined,
      isLoading: true,
      refetchPossible: false,
      selectedDropOffPoint: undefined,
      addressQuery: '',
    }
  },
  validations: {
    addressQuery: { required },
  },
  computed: {
    ...mapGetters(['allowedCarrierCodes']),
    ...mapState({
      access_token: (state) => state.servicePointsToken,
      allowedCarriers: (state) => state.allowedCarriers,
      shippingProducts: (state) => state.shippingProducts,
      country: (state) => {
        if (state.parcel === undefined || state.parcel.country === undefined) {
          // FIX: RETURN-PORTAL-3Y on Sentry
          return ''
        }
        return state.parcel.country.iso_2
      },
      parcelAddress: (state) => {
        if (state.parcel === undefined) {
          return ''
        }
        return `${state.parcel.address} ${state.parcel.city}`
      },
      parcelLocation: (state) => {
        if (state.parcel === undefined) {
          return ''
        }
        return state.parcel.to_coordinates
      },
      parcelWeight: (state) => {
        if (state.parcel === undefined) {
          return ''
        }
        return state.parcel.weight
      },
    }),

    hasErrorFetchingFirstPage() {
      return this.fetchingError === ErrorTypes.FETCH_DROP_OFF_POINTS_FIRST_PAGE
    },

    hasErrorFetchingNextPage() {
      return this.fetchingError === ErrorTypes.FETCH_DROP_OFF_POINTS_NEXT_PAGE
    },

    allPoints() {
      const normalizePoint = (point) => {
        const isSelected = this.selectedDropOffPoint !== undefined && this.selectedDropOffPoint.id === point.id
        return {
          id: point.id,
          code: point.code,
          company_name: point.name,
          address_1: point.street,
          house_number: point.house_number,
          postal_code: point.postal_code,
          city: point.city,
          carrier: this.allowedCarriers && this.allowedCarriers[point.carrier],
          distance: point.distance,
          open_upcoming_week: point.open_upcoming_week,
          isSelected,
          extra_data: point.extra_data,
        }
      }

      if (!this.dropOffPoints.length && !this.fetchingError) {
        return SKELETON_PLACEHOLDER.map(normalizePoint)
      }

      return this.dropOffPoints.map(normalizePoint)
    },

    disableNext() {
      return this.selectedDropOffPoint === undefined || this.allPoints.length === 0
    },
  },

  async created() {
    this.loadMore()
    // The initial service point list is based on parcel coordinates. Update the addressQuery
    // __AFTER__ loading the data just to update the search field initial value, without changing
    // the current behavior
    this.addressQuery = this.parcelAddress
  },

  methods: {
    search() {
      this.refetchPossible = false
      this.dropOffPoints = []
      this.dropOffPointFetchUrl = undefined
      this.loadMore()
    },

    async loadMore() {
      this.isLoading = true
      this.fetchingError = undefined

      const { access_token, country } = this
      const searchParams = {
        access_token,
        country,
        ...(this.parcelLocation || {}), // A parcel might not have geocoding data
        radius: 10000, // 10km
        page_size: 10, // max 10 per page,
        weight: this.parcelWeight,
      }

      if (this.$route?.query?.isLabelless && Array.isArray(this.shippingProducts)) {
        searchParams.carrier = this.shippingProducts
          .reduce((acc, item) => {
            // Check at method level for functionalities, as we do not have control
            // over functionalities at product level and they seem to have been removed
            const labelless = item.methods?.find((method) => method.functionalities?.labelless)

            if (labelless) {
              acc.push(item.service_points_carrier)
            }
            return acc
          }, [])
          .join(',')
      } else {
        searchParams.carrier = this.allowedCarrierCodes.join(',')
      }

      if (this.addressQuery !== '') {
        delete searchParams.latitude
        delete searchParams.longitude
        searchParams.address = this.addressQuery
      }

      const requestURL =
        this.dropOffPointFetchUrl !== undefined
          ? this.dropOffPointFetchUrl
          : endpoint('service-points', searchParams, process.env.VUE_APP_SERVICE_POINTS_API_BASE_URL)

      try {
        const response = await request(requestURL)
        const responseData = await response.json()

        this.dropOffPoints = this.dropOffPoints.concat(responseData)
        if (response.headers.has('Link')) {
          this.setDropOffPointFetchUrl(response.headers.get('Link'))
        }
      } catch (err) {
        this.refetchPossible = true
        if (this.dropOffPoints.length === 0) {
          this.fetchingError = ErrorTypes.FETCH_DROP_OFF_POINTS_FIRST_PAGE
        } else {
          this.fetchingError = ErrorTypes.FETCH_DROP_OFF_POINTS_NEXT_PAGE
        }
      } finally {
        this.isLoading = false
      }
    },

    dropOffPointChanged(_, id) {
      if (!id) { return }
      this.selectedDropOffPoint = this.dropOffPoints.find((point) => point.id === id)
    },

    setDropOffPointFetchUrl(fetchURL) {
      const links = fetchURL.split(',').map((x) => {
        const [url, rel] = x.split(';')
        return {
          url: url.trim().replace(/^</, '').replace(/>$/, '').replace(/http:/g, 'https:'),
          isNext: rel.includes('next'),
        }
      })
      const next = links.find((x) => x.isNext)

      if (next === undefined) {
        this.dropOffPointFetchUrl = undefined
        this.refetchPossible = false
      } else {
        this.dropOffPointFetchUrl = next.url
        this.refetchPossible = true
      }
    },

    next() {
      if (this.$route?.query?.isLabelless && Array.isArray(this.shippingProducts)) {
        this.$store.commit(APP_SET_LOCATION_TYPE, 'drop_off_labelless')
        this.$store.commit(APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT, this.selectedDropOffPoint)
      } else {
        this.$store.commit(APP_SET_LOCATION_TYPE, 'dropoff')
        this.$store.commit(APP_SET_SELECTED_DROP_OFF_POINT, this.selectedDropOffPoint)
      }
      this.$router.push({ name: 'return-methods' })?.catch(handleRouteRejection)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_variables.scss';
.dropoff-search {
  width: 100%;
}
.dropoff-search__controls {
  position: relative;
}
.dropoff-search__label {
  margin: 2.25rem 0 0.625rem;
  font-weight: 700;
}
.dropoff-search__input {
  border: $default-border;
  border-radius: $default-border-radius;
  padding: 1rem;

  display: block;
  width: 100%;

  &:focus {
    box-shadow: $focus-shadow;
  }
}
.dropoff-search__submit {
  display: block;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
  border: none;
  background-color: transparent;
  border-top-right-radius: $default-border-radius;
  border-bottom-right-radius: $default-border-radius;
  cursor: pointer; /* stylelint-disable-line */

  &:focus {
    box-shadow: $focus-shadow;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed; /* stylelint-disable-line */
  }
}
</style>
